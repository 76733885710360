import { post } from '@/utils/http'
// 用户实名认证状态
export const checkSubject = function (data) {
    return post({
        url: "/app/userInfo/checkSubject",
        data
    })
}
// 用户实名认证状态
export const userInviteShareWxInfo = function (data) {
    return post({
        url: "/app/user/userInviteShareWxInfo",
        data
    })
}

// 我的收益
export const getIncome = function (data) {
  return post({
      url: "/app/business/user/register/activit/account/getInfo",
      data
  })
}

// 收益记录
export const recordList = function (data) {
    return post({
        url: "/app/business/user/register/activit/record/list",
        data
    })
  }

  // 邀请记录
export const inviteList = function (data) {
    return post({
        url: "/app/business/user/register/activit/record/inviteList",
        data
    })
  }

// 获取活动设置-规则
export const getInfo = function (data) {
    return post({
        url: "/app/business/user/register/activit/config/getInfo",
        data
    })
  }

  //明细-提现记录
  export const getDrawList = function (data) {
    return post({
        url: "/app/draw/getDrawList",
        data
    })
  }
  //提现
export const applyDraw = function (data) {
    return post({
        url: "/app/draw/applyDraw",
        data
    })
  }
  // 提现规则及协议
export const getDrawConfig = function (data) {
    return post({
        url: '/app/draw/getDrawConfig',
        data
    })
}